// Custom Theming for Angular Material
@use "@angular/material"as mat;
@use "ag-grid-community/styles"as ag;

// For more information: https://material.angular.io/guide/theming
@import "colors.scss";
@import "responsive.scss";
@import "leaflet.scss";

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

/* Add application styles & imports to this file! */
// @import "ag-grid-community/styles/ag-grid.scss";
// @import "ag-grid-community/styles/ag-theme-material/sass/ag-theme-material-mixin";


@include ag.grid-styles((theme: material,
        material-primary-color: $hydrao-blue,
        material-accent-color: $hydrao-blue));


@import "leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "leaflet.markercluster/dist/MarkerCluster.css";
@import "leaflet/dist/leaflet.css";
@import "leaflet-control-geocoder/dist/Control.Geocoder.css";
@import "intl-tel-input/build/css/intlTelInput.css";

$mat-hydrao-blue: (50: #e0f1f9,
    100: #b3ddf0,
    200: #80c6e6,
    300: #4dafdb,
    400: #269ed4,
    500: #008dcc,
    600: #0085c7,
    700: #007ac0,
    800: #0070b9,
    900: #005dad,
    A100: #d7eaff,
    A200: #a4cfff,
    A400: #71b4ff,
    A700: #58a7ff,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: white,
        A700: white,
    ),
);
$mat-hydrao-orange: (50: #fceae4,
    100: #f8cabb,
    200: #f4a78d,
    300: #f0835f,
    400: #ec693d,
    500: #e94e1b,
    600: #e64718,
    700: #e33d14,
    800: #df3510,
    900: #d92508,
    A100: #ffffff,
    A200: #ffd4cf,
    A400: #ffa69c,
    A700: #ff8f83,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: white,
        A700: white,
    ),
);

@include mat.core();


@import "@angular/material/prebuilt-themes/indigo-pink.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hydrao-webapp-primary: mat.define-palette($mat-hydrao-blue);
$hydrao-webapp-accent: mat.define-palette($mat-hydrao-orange);
// The warn palette is optional (defaults to red).
$hydrao-webapp-warn: mat.define-palette($mat-hydrao-orange);
// Create the theme object (a Sass map containing all of the palettes).
// $hydrao-webapp-theme: mat.define-light-theme($hydrao-webapp-primary, $hydrao-webapp-accent, $hydrao-webapp-warn);
$hydrao-webapp-theme: mat.define-light-theme((
 color: (
   primary:$hydrao-webapp-primary,
   accent: $hydrao-webapp-accent,
   warn: $hydrao-webapp-warn
 ),
 density: 0,
));

@include mat.all-component-themes($hydrao-webapp-theme);

// @include mat.core-theme($hydrao-webapp-theme);
// @include mat.button-theme($hydrao-webapp-theme);
// @include mat.form-field-theme($hydrao-webapp-theme); // Form-Field theme
// @include mat.input-theme($hydrao-webapp-theme); // Form-Field theme



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($hydrao-webapp-theme);

@import "d3-bar.scss";
@import "d3-line.scss";
@import "d3-donut.scss";
@import "d3-half-donut.scss";
@import "ag-grid.scss";
@import "buttons.scss";
@import "mat-dialog.scss";
@import "tel-input.scss";
@import "joyride.scss";
@import "card.scss";
@import "form.scss";
@import "tabs.scss";

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background: $bg-color-center; //#f0eceb; // #efefef; //#EDF0F2;
    color: $hydrao-dark-grey;
}

.normal-text {
    font-size: 16px;
    color: $hydrao-dark-grey;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

// .mat-stroked-button-red {
//     @extend .mat-raised-button;
//     border: 1px solid $hydrao-orange !important;
//     // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
//     // margin: 2px;
//     background: #ffffff !important;
//     color: $hydrao-orange !important;
//     &:hover {
//         background: $hydrao-orange !important;
//         color: #ffffff !important;
//     }
// }

.hydrao-title {
    color: $hydrao-dark-blue;
    font-size: 1.6em;
    font-weight: 400;
}

.hydrao-section-title {
    color: $hydrao-dark-blue;
    font-size: 1.3em;
    font-weight: 100;
    text-transform: uppercase;
}

.hydrao-card-title {
    color: $hydrao-grey;

    font-weight: 500;
    // text-transform: Capitalize;
    text-align: center;
    // border-bottom: 1px solid $bg-color-center;
    /* margin-top: -15px!important; */
    /* margin-left: -15px!important; */
    /* margin-right: -15px!important; */
    margin: -12px -15px 15px -15px !important;
    font-size: 1.5em;

    @media screen and (max-width: 550px) {
        font-size: 1.2em;
    }
}

.hydrao-label {
    color: $hydrao-lite-grey;
    padding-right: 3px;
}

.hydrao-value {
    color: $hydrao-dark-grey;
}

.hydrao-top-bar {
    background: $bg-color-menu;
    //box-shadow: 0 0 3px 0 $hydrao-dark-blue;
    // margin-bottom: 10px;
    min-height: 50px;
    margin-left: -5px; //white bar on some browser :(
    padding-left: 5px;
}

.mat-drawer-side {
    border: none !important;
}

.mat-drawer-content {
    overflow-x: hidden !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.place-label .mat-button-focus-overlay {
    background-color: transparent !important;
}

.colored-parent,
.colored>div {
    background-color: $hydrao-blue !important;
}

.error-snackbar {
    background-color: $hydrao-orange;
    color: #ffffff;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
        color: #ffffff;
    }
}

.success-snackbar {
    background-color: $hydrao-green;
    color: #ffffff;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
        color: #ffffff;
    }
}

.cell-text .datatable-body-cell-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// .area-line {
//     .ngx-charts .line-highlight {
//         display: block;
//     }
//     .bar:hover{
//         opacity: 0.5!important;
//     }
// }

// .temperature-graph {
//     g.line-chart > g:last-of-type > g:nth-child(n) g.line-series > path {
//         stroke-width: 4;
//         stroke-linecap: round;
//     }

//     g[ngx-charts-y-axis]:nth-of-type(3) text {
//         fill: $hydrao-graph-6 !important;
//     }

//     g[ngx-charts-y-axis]:nth-of-type(2) text {
//         fill: $hydrao-graph-1 !important;
//     }
// }

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-form-field-appearance-legacy.mat-form-field-disabled.solid-underline .mat-form-field-underline {
//     // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
//     // background-size: 4px 100%;
//     // background-repeat: repeat-x;
//     background-color: rgba(0, 0, 0, 0.42) !important;
//     background-image: none !important;
//     background-size: auto !important;
//     //background: $hydrao-dark-grey!important;
// }



.mat-ripple {
    z-index: 3000;
}






.top-loading-spinner {
    &.mat-mdc-progress-bar {
        height: 3px !important;
        opacity: 0.7;
        stroke-linecap: round;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
    .mat-progress-bar-buffer {
        background: transparent !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
    .mat-progress-bar-background {
        fill: none;
    }
}

.mat-mdc-tooltip {
    background: adjust-color($hydrao-dark-blue, $alpha: -0.1) !important;
    font-size: 1em;
}

ngx-skeleton-loader:not(.on-grey) .loader {
    background-color: $hydrao-ultralite-grey !important;
}

ngx-skeleton-loader.on-grey .loader {
    background-color: $hydrao-lite-grey !important;
}

.threshold-alert-icon {
    color: $hydrao-graph-6;
}

.smart-alert-icon {
    color: $hydrao-graph-2;
}

.dysfunction-alert-icon {
    color: $hydrao-orange;
}


hydrao-timezone-select {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-subscript-wrapper {
        overflow: visible;
        width: auto;
    }
}
@import "colors.scss";

hydrao-half-donut {
    .label {
        fill: $hydrao-mid-grey;
        @media(min-width: 500px) {
            font-size: 3rem;
        }
        @media(max-width: 500px) { 
            font-size: 2rem;

        }

        // font-size: 50px;
        
        font-weight: 100;
    }

    .remain-arc {
        fill:#f8f8f8;
    }
}

hydrao-half-donut.color-1 {
    .val-arc {
        fill: $hydrao-sh-color;
    }
}

hydrao-half-donut.color-2 {
    .val-arc {
        fill: $hydrao-meter-color;
    }
}


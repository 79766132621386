@import "colors.scss";

.bubble-marker,
.bubble-cluster {
    border-radius: 100px;
    color: white;
    text-align: center;
    outline: none !important;
    & > * {
        position: relative;
        display: block;
        top: 50%;
        height: 20px;
        margin-top: -10px;
    }
    //opacity: 0.6;
}

.bubble-marker {
    transition: background ease-in 0.5s;
    background: rgba($hydrao-blue, 0.5); //adjust($hydrao-blue, $alpha: -0.4);
    border: 1px solid $hydrao-blue !important;
    box-sizing: content-box;
    &.selected {
        background: rgba($hydrao-blue, 0.7);
        border: 2px solid $hydrao-blue !important;
        //outline: 6px solid rgba($hydrao-orange, 0.4) !important;
    }
}

.bubble-cluster {
    background: rgba($hydrao-blue, 0.3);
    box-sizing: content-box;
    font-size: 1.2em;
    font-weight: bolder !important;
    & > div {
        background: rgba($hydrao-blue, 0.5); //adjust($hydrao-blue, $alpha: -0.4);
        border-radius: inherit;
        top: 0;
    }
}

.bubble-polygon{
    stroke: rgba($hydrao-blue, 0.8);
    fill: $hydrao-blue;
}

hydrao-dashboard {
    #bubble-map {
       
       
    }
}

hydrao-places-global-map {
    #bubble-map {
        margin-left: 0;
        margin-right: 0;
    }
}

.marker-cluster-small div {
    background-color: $hydrao-green;
}

.marker-cluster-medium div {
    background-color: $hydrao-graph-8;
}


.marker-cluster-large div {
    background-color: $hydrao-orange;
}


.leaflet-alert-icon {
    background: $hydrao-orange;
    mask: url("../assets/alert.svg") no-repeat center;
    margin-top: -20px !important;
}

hydrao-alert-map {
    .leaflet-interactive {
        stroke: $hydrao-orange!important;
    }

    .marker-cluster {
        background-color:  rgba( $hydrao-orange, 0.5)!important;
        div {
            background-color: $hydrao-orange!important;
            color: white;
        }
    }
}




hydrao-alert-map{
    .leaflet-map {
        margin-left: 0;
        margin-right: 0;
    }
}
// Bar graph

.stripes {
    stroke: rgba($color: #ffffff, $alpha: 0.6);
    stroke-width: 1.2;
}

div.hydrao-bar-tooltip {
    position: absolute;
    color: #fff;
    border-radius: 4px;
    margin: 14px;
    max-width: 250px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

hydrao-graph-d3,
hydrao-raw-signal-graph {
    .hydrao-bar-label {
        font-size: 0.8rem;
        @media screen and (max-width: 600px) {
            font-size: 0.6rem !important;
        }
        fill: $hydrao-graph-7;
        font-weight: 300;
    }
    .hydrao-bar.selectable,
    .hydrao-stacked-bar.selectable,
    .hydrao-bar1.selectable,
    .hydrao-bar2.selectable {
        transition: fill ease-in-out 0.2s;
        cursor: pointer;
    }

    .hydrao-bar.flashing {
        animation-timing-function: linear;
        animation-name: color-blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    .hydrao-bar-label {
        fill: $hydrao-graph-1;
    }

    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-1;
    }
    .hydrao-bar.selected {
        fill: darken($hydrao-graph-1, 8%); //$hydrao-dark-blue;
    }

    .hydrao-bar.bar1:not(.selected) {
        fill: $hydrao-sh-color;
    }
    .hydrao-bar.bar1.selected {
        fill: $hydrao-sh-color; //TODO
    }

    .hydrao-bar.bar2:not(.selected) {
        fill: $hydrao-meter-color;
    }
    .hydrao-bar.bar2.selected {
        fill: $hydrao-meter-color; //TODO
    }

    .hydrao-stacked-bar {
        fill: url("#diagStripes");
        // opacity: 0.3;
    }

    .blue-axis {
        color: $hydrao-dark-blue;
        .tick {
            color: transparent;
        }
        text {
            font-size: 0.9rem;
            color: $hydrao-dark-blue;
        }
    }

    .hydrao-line {
        stroke: $hydrao-graph-8;
    }

    .hydrao-line2 {
        stroke: $hydrao-graph-5;
    }

    .hover-point {
        fill: $hydrao-graph-8;
    }

    .hover-point-2 {
        fill: $hydrao-graph-5;
    }

    .hover-text {
        fill: scale-color($hydrao-graph-8, $lightness: -50%);
        // stroke: scale-color($hydrao-graph-8, $lightness: -50%);
    }
    .hover-text-2 {
        fill: scale-color($hydrao-graph-5, $lightness: -50%);
        //stroke: scale-color($hydrao-graph-5, $lightness: -50%);
    }

    #tooltip-bg {
        feFlood {
            flood-color: scale-color($hydrao-graph-8, $lightness: 50%);
            flood-opacity: 80%;
        }
    }

    #tooltip-bg2 {
        feFlood {
            flood-color: scale-color($hydrao-graph-5, $lightness: 50%);
            flood-opacity: 80%;

        }
    }

    // .hover-text,
    // .hover-text-2 {
    //     //stroke: black;
    //     // stroke-width: 2.5px;
    //     // paint-order: stroke;
    //     // font-weight: 600;
    //     // stroke-opacity: 0.7;
    // }

    .y1.axis,
    .y.axis {
        color: $hydrao-mid-grey;
        stroke-width: 0.5;
        stroke-dasharray: (1, 1);
        text {
            font-size: 0.8rem;
            @media screen and (max-width: 550px) {
                font-size: 0.7rem !important;
            }
            color: $hydrao-graph-1;
        }
    }

    .y2.axis {
        .domain {
            display: none;
        }
        text {
            font-size: 0.9rem;
            @media screen and (max-width: 550px) {
                font-size: 0.7rem !important;
            }
            color: $hydrao-graph-7;
        }
    }

    // @media screen and (max-width: 600px) {
    //     .x.axis {
    //         & > .tick {
    //             &:not(:nth-child(2)):not(:last-child) {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}

hydrao-graph.color1 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-1 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-1, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-1 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-1 !important;
        }
    }
}
hydrao-graph.color2 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-2 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-2, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-2 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-2 !important;
        }
    }
}
hydrao-graph.color3 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-3 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-3, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-3 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-3 !important;
        }
    }
}
hydrao-graph.color4 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-4 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-4, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-4 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-4 !important;
        }
    }
}
hydrao-graph.color5 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-5 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-5, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-5 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-5 !important;
        }
    }
}
hydrao-graph.color6 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-6 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-6, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-6 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-6 !important;
        }
    }
}
hydrao-graph.color7 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-7 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-7, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-7 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-7 !important;
        }
    }
}
hydrao-graph.color8 hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-graph-8 !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-graph-8, $lightness: -30%); //$hydrao-dark-blue;
    }
    .hydrao-bar-label {
        fill: $hydrao-graph-8 !important;
    }
    .y1.axis {
        text {
            color: $hydrao-graph-8 !important;
        }
    }
}

hydrao-graph.colorgray hydrao-graph-d3 {
    .hydrao-bar:not(.selected) {
        fill: $hydrao-lite-grey !important;
    }
    .hydrao-bar.selected {
        fill: scale-color($hydrao-lite-grey, $lightness: -30%);
    }
    .hydrao-bar-label {
        fill: scale-color($hydrao-lite-grey, $lightness: -30%) !important;
    }
    .y1.axis {
        text {
            color: $hydrao-lite-grey !important;
        }
    }
}

.meter-graph {
    g.line-chart > g:last-of-type > g:nth-child(n) g.line-series > path {
        stroke-width: 4;
        stroke-linecap: round;
    }

    g[ngx-charts-y-axis]:nth-of-type(2) text {
        fill: $hydrao-graph-6 !important;
    }

    g[ngx-charts-y-axis]:nth-of-type(1) text {
        fill: $hydrao-graph-1 !important;
    }
}

@keyframes color-blink {
    to {
        opacity: 0.4;
    }
}


.sh-graph {
    hydrao-graph-d3 .hydrao-bar:not(.selected) {
        fill: $hydrao-sh-color;
    }

    hydrao-graph-d3 .hydrao-bar.selected {
        fill: scale-color($hydrao-sh-color, $lightness: -30%);
    }
}




.meter-graph {
    hydrao-graph-d3 .hydrao-bar:not(.selected) {
        fill: $hydrao-meter-color;
    }

    hydrao-graph-d3 .hydrao-bar.selected {
        fill: scale-color($hydrao-meter-color, $lightness: -30%);
    }
}

hydrao-raw-signal-graph {
    .hydrao-bar {
        fill: $hydrao-meter-color!important;
    }
}
.mat-mdc-tab-nav-bar.on-dark,
.mat-mdc-tab-group.on-dark {
   
    .mat-mdc-tab-link, .mat-mdc-tab {
        .mdc-tab__text-label {
            color: #ffffff !important;
        }

        opacity: 1;
        // text-transform: capitalize;
        font-weight: 300;
        font-size: 1em;

    }

    .mat-ink-bar {
        height: 5px;
        background-color: $hydrao-blue;
    }
}


.mat-mdc-tab-group:not(.on-dark) {
    .mat-mdc-tab {
        background-color: #ffffff!important;
    }
}
.mat-mdc-tab-nav-bar,
.mat-mdc-tab-group{
    .mat-mdc-tab-links {
        margin-top: 2px;
    }

    .mdc-tab-indicator>span {
        border-top-width: 5px;
    }

    .mat-icon {
        margin-right: 10px;
    }
}


/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-header-pagination {
    background-color: $bg-color-menu;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination-chevron {
        border-color: white;
    }
}

.mat-mdc-tab-group.on-dark {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-mdc-tab-list {
        background: $bg-color-menu;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label {
        color: #ffffff;
        opacity: 1;
        // text-transform: capitalize;
        font-weight: 300;
        font-size: 1em;

        .mat-icon {
            margin-right: 10px;
        }
    }

    .mat-ink-bar {
        height: 5px;
        background-color: $hydrao-blue;
    }
}


.ag-theme-material {
    position: relative;
    [class^="ag-"]:not(.ag-row-selected,.selected){
        background: none !important;
    }
    .ag-root-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .ag-header-cell-label {
        text-transform: lowercase;
        font-size: 1.2em;
        font-weight: 500;
        color: $hydrao-mid-grey;
    }
    .ag-cell {
        padding-right: 10px;
        padding-left: 10px;
    }

    .ag-header {
        border-bottom-color: $bg-color-center;
    }

    .ag-row {
        border-color: transparent;
    }

    .ag-row-selected, .selected {
        background-color: $bg-color-center;
    }

    .ag-checkbox-input-wrapper::after {
        color: $hydrao-dark-grey;
    }

    .ag-row {
        color: $hydrao-dark-grey;
    }

    .ag-center-cols-viewport {
        overflow-x: auto;
    }
}

.grid-footer {
    height: 40px;
    border-top: 1px solid $bg-color-center;
    .footer-text {
        color: $hydrao-mid-grey;
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
}

.ag-cell.no-padding {
    padding:0!important;
}
button:not(.lower-case) {
    text-transform: uppercase;
}

.mdc-raised-button,
.mdc-unelevated-button,
.mat-mdc-outlined-button,
.mdc-button {
    border-radius: 20px !important;
    padding: 0 25px !important;
}

.mat-mdc-outlined-button {
    // @extend .mat-raised-button;
    border: 1px solid $hydrao-blue !important;
    // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
    // margin: 2px;
    background: #ffffff !important;

    &:not(.mat-accent) {
        color: $hydrao-blue !important;

        &:hover {
            color: #ffffff !important;
            background: $hydrao-blue !important;
        }
    }

    &.mat-accent {
        border: 1px solid $hydrao-orange !important;
        color: $hydrao-orange !important;

        &:hover {
            color: #ffffff !important;
            background: $hydrao-orange !important;
        }
    }

   
}

.button-on-menu {
    border: 1px solid $hydrao-blue !important;
    height: 25px !important;
    line-height: 25px !important;

    .btn-text {
        white-space: nowrap!important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    &:not(.mat-button-disabled) {
        // box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.42) !important;
        color: $hydrao-blue !important;

        &.orange {
            color: $hydrao-orange !important;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    &.mat-button-disabled {
        color: $hydrao-mid-grey !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    * {
        vertical-align: top !important;
    }

    &:not(.icon-only) {
        font-weight: 400 !important;

        .mat-icon {
            margin-left: -13px !important;
            margin-right: 5px !important;
        }

        padding: 0 20px !important;
    }

    &.icon-only {
        padding: 0 !important;
        width: 35px !important;
        min-width: 35px;
        overflow: hidden;

        .mat-icon {
            margin-right: 0 !important;
        }
    }
}

.mdc-raised-button,
.mdc-unelevated-button,
.mdc-button {
    &:not(.icon-only) .mat-icon {
        margin-right: 5px;
    }
}

//tree button styling.
button.datatable-tree-button {
    background: none !important;
    border: none !important;
    cursor: pointer;
}




.small-button {
    background: #ffffff;
    height: 26px !important;
    line-height: 22px !important;

    &.icon-only {
        .mat-icon {
            font-size: 24px;
            font-size: 24px;
            margin-left: 5px;
            margin-top: -5px;
        }
    }

}





/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-label-content {
    line-height: 30px !important;
    font-size: 15px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked {
    background-color: lighten($hydrao-blue, 40%);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-focus-overlay {
    background-color: lighten($hydrao-blue, 70%) !important;
}




.mat-button-toggle-group.on-dark {
    border: 1px solid $hydrao-blue;


    
    
    .mat-button-toggle {
        color: #ffffff;

        background: rgba(255, 255, 255, 0.15);

    
        &.mat-button-toggle-checked {
            background: rgba(255, 255, 255, 0.3);
        }

    
        &+.mat-button-toggle {
            border-left: 1px solid $hydrao-blue;
        }
    }
}
@import "colors.scss";

$line1-color : $hydrao-graph-2;
$line2-color : $hydrao-graph-5;


.line-legend {
   
    .legend1-watch {
        background-color: $line1-color;
    }

    .legend2-watch {
        background-color: $line2-color;

    }
}

hydrao-line-chart-d3 {
    #line1Grad {
        .stop1 {
            stop-color: rgba($color: $line1-color, $alpha: 0.6);
        }
        .stop2 {
            stop-color: rgba($color: $line1-color, $alpha: 0);
        }
    }

    #line2Grad {
        .stop1 {
            stop-color: rgba($color: $line2-color, $alpha: 0.6);
        }
        .stop2 {
            stop-color: rgba($color: $line2-color, $alpha: 0);
        }
    }

    rect.selection {
        fill: $hydrao-mid-grey;
    }

    .context .area {
        fill: $hydrao-dark-grey;
    }

    .area1 {
        fill: url(#line1Grad);
        stroke: $line1-color;
        clip-path: url(#clip);
    }

    .area2 {
        fill: url(#line2Grad);
        stroke: $line2-color;
        clip-path: url(#clip);
    }

    .zoom {
        cursor: move;
        fill: none;
        pointer-events: all;
    }

    .axis {
        fill: $hydrao-dark-grey;
    }

    .hover-point {
        fill: $line1-color,
    }

    .hover-point-2{
        fill: $line2-color,
    }

    .hover-text {
        fill: $line1-color,
    }

    .hover-text-2 {
        fill: $line2-color,
    }

    .hover-line {
        stroke: $hydrao-dark-grey;
        fill: $hydrao-dark-grey;
    }
    .domain {
        fill: none;
    }
}

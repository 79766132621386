@import "colors.scss";

.joyride-step__container {
    border-radius: 15px !important;
    max-width: 90vw;
    .joyride-step__header {
        padding:0 20px 0 20px;
        margin: -10px;
        border-bottom: 1px solid $bg-color-center;

    }
    .joyride-step__body {
        font-family: Roboto, "Helvetica Neue", sans-serif;
        color: $hydrao-dark-grey;

    }
    .joyride-step__title {
        color: $hydrao-dark-blue;
        font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
        font-weight: 100;
        text-transform: uppercase;
        text-align: center;
        margin-right: 20px;
        // margin: -25px -24px 15px -24px !important;
        //margin:0 -18px 0 -18px!important;
        padding: 0 ;
        //width: 100%;
        min-width: 100%;
        font-size: 1.5em;

        @media screen and (max-width: 550px) {
            font-size: 1.2em;
        }
    }

    .joyride-button {
        border-radius: 20px !important; 
        color: #ffffff !important;
        background: $hydrao-blue !important;
    }

    .joyride-step__counter {
        color: $hydrao-dark-grey;

    }
}

// .backdrop-container {
//     &#backdrop-meterDetails {
//          display: none;
//     }
// }

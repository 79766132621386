hydrao-donut-chart {
    //:host { display: block; }

    .line {
        stroke: $hydrao-mid-grey;
        stroke-width: 1px;
        fill: none;
    }
    .labels {
        fill: $hydrao-dark-grey;
        @media (max-width: 500px) {
            font-size: 0.8em;
        }
        .usage-percent {
            fill: $hydrao-mid-grey;
        }
    }
    .labels,
    .lines {
        @media (max-width: 1000px) {
            display: none;
        }
    }

    path.slice {
        // stroke-width: 2px;
        // stroke: $bg-color-center;
        cursor: pointer;
        &.color1 {
            fill: $hydrao-graph-1;
        }
        &.color2 {
            fill: $hydrao-graph-2;
        }
        &.color3 {
            fill: $hydrao-graph-3;
        }
        &.color4 {
            fill: $hydrao-graph-4;
        }
        &.color5 {
            fill: $hydrao-graph-5;
        }
        &.color6 {
            fill: $hydrao-graph-6;
        }
        &.color7 {
            fill: $hydrao-graph-7;
        }
        &.color8 {
            fill: $hydrao-graph-8;
        }
        &.colorgray {
            fill: $hydrao-lite-grey;
        }
    }
}



@import "colors.scss";
.mdc-card {
    border-radius: 15px !important;
    padding: 15px;
}

.sh-metric {
    .mat-icon {
        color: $hydrao-sh-color!important;
    }
}

.meter-metric {
    .mat-icon {
        color: $hydrao-meter-color!important;
    }
}
.mdc-text-field {
    background-color: transparent !important;
}


.mat-mdc-form-field.on-dark.mat-form-field-appearance-fill {
    mat-icon {
        color: #ffffff;
    }

    .mat-form-field-underline {
        background-color: $hydrao-blue; // rgba(255, 255, 255, 0.9);
    }

    .mat-form-field-ripple {
        background-color: $hydrao-blue;
    }

    .mat-mdc-text-field-wrapper {
        padding: 0;
        .mat-mdc-form-field-flex {
            background: rgba(255, 255, 255, 0.15);
            border-radius: 5px 5px 0 0;
            color: white;
            padding: 5px;
        }
    } 


    .mat-mdc-input-element,
    .mat-input-element::placeholder {
        color: #ffffff!important;
    }

    .mat-date-range-input-separator  {
        color: #ffffff!important;
    }

    .mat-form-field-placeholder {
        color: #ffffff !important;
    }

    .mdc-text-field {
        
        .mdc-line-ripple::before, .mdc-line-ripple::after {
            border-bottom-color: $hydrao-blue!important;
        }
        
        .mat-mdc-form-field-flex {
            margin-bottom: -10px;
        }
        input::placeholder {
            color: rgba(255,255,255,0.6)!important;
        }

        .mdc-floating-label {
            color: rgba(255,255,255,0.6)!important;

        }
        .mat-mdc-icon-button {
            color: white;
        }
        
    }
}

.mat-mdc-form-field.on-dark:not(.mat-form-field-appearance-fill) {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper .mat-form-field-flex {
        background: rgba(255, 255, 255, 0.15);
        border-radius: 5px 5px 0 0;
        color: white;
        padding: 5px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
        background-color: $hydrao-blue; // rgba(255, 255, 255, 0.9);
    }

    .mat-datepicker-toggle,
    .mat-date-range-input-separator {
        color: #ffffff!important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
        color: rgba(255, 255, 255, 0.6);
    }

}

.mat-mdc-form-field.top-search-box {

   
    .mat-form-field-underline {
        background-color: $hydrao-blue; // rgba(255, 255, 255, 0.9);
    }

    .mat-form-field-ripple {
        background-color: $hydrao-blue;
    }
    mat-mdc-input-element,
    .mat-input-element::placeholder {
        color: #ffffff!important;
    }

    .mat-date-range-input-separator  {
        color: #ffffff!important;
    }

    .mat-form-field-placeholder {
        color: #ffffff !important;
    }

    .mdc-text-field {
        
        .mdc-line-ripple::before, .mdc-line-ripple::after {
            border-bottom-color: $hydrao-blue!important;
        }
        
        .mat-mdc-form-field-flex {
            margin-bottom: -10px;
        }

       
        
    }

 
}

.no-outline {
    .mdc-notched-outline {
        opacity: 0;
    }
}



$hydrao-blue : #008dcc;
$hydrao-light-blue : #60c6f2;
$hydrao-orange: #e94e1b;

$hydrao-light-bg-blue: #F5F8FA;
$hydrao-dark-blue: #266580;
$hydrao-deep-dark-blue: #153847;
$hydrao-green: #00ca36;






$hydrao-dark-grey: #545454;
$hydrao-grey: #666666;
$hydrao-mid-grey: #8c8c8c;
$hydrao-lite-grey: #b7b7b7;
$hydrao-ultralite-grey: #dddddd;

$hydrao-meter-color: #EE9A1E;
$hydrao-sh-color: #12A499;



$bg-color-menu: #ffffff;
$bg-color-center:  $hydrao-light-bg-blue;


// $hydrao-graph-1: #00778B;
// $hydrao-graph-2: #00888A;
// $hydrao-graph-3: #00987D;
// $hydrao-graph-4: #3CA666;
// $hydrao-graph-5: #76B04B;
// $hydrao-graph-6: #AFB532;


$hydrao-graph-1: #00778B;
$hydrao-graph-2: #14877F;
$hydrao-graph-3: #289672;
$hydrao-graph-4: #3CA666;
$hydrao-graph-5: #76AE4C;
$hydrao-graph-6: #AFB532;
$hydrao-graph-7: #D0BA3A;
$hydrao-graph-8: #F0BF42;
@import "colors.scss";

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container {
    
    .mat-mdc-dialog-surface {
        padding:24px;
        border-radius: 15px !important;
    }


    .mat-mdc-dialog-title {
        color: $hydrao-grey!important;

        font-weight: 500!important;
        // text-transform: capitalize;
        text-align: center;
        border-bottom: 1px solid $bg-color-center;
        margin: -40px -24px 15px -24px !important;
        padding: 0 25px!important;
        font-size: 1.5em!important;

        @media screen and (max-width: 550px) {
            font-size: 1.2em;
        }
    }
}